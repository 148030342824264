"use client";
import { Bg_img } from "@/public";
import React from "react";
import Image from "next/image";

type Props = {};

const comparatifs = [
  {
    feature: "Tableau de bord projet",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Jalons et planning",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Gestion des budgets",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Gestion des risques",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Simulation du plan de charges",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Gestion de l'équipe",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Partage de documents",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Reporting de projet Powerpoint",
    free: true,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Intégration avec des outils tiers",
    free: false,
    premium: "option",
    enterprise: "option",
  },
  {
    feature: "Import de données",
    free: false,
    premium: "option",
    enterprise: "option",
  },
  {
    feature: "Historisation de projet automatique",
    free: false,
    premium: true,
    enterprise: true,
  },
  {
    feature: "Analyse et conseil par IA",
    free: "Limite de temps",
    premium: true,
    enterprise: true,
  },
  {
    feature: "Personnalisation avancée",
    free: false,
    premium: false,
    enterprise: true,
  },
  {
    feature: "Gestion des contrôles d'accès",
    free: false,
    premium: false,
    enterprise: true,
  },
  {
    feature: "Support dédié et SLA garanti",
    free: false,
    premium: false,
    enterprise: true,
  },
];

const pricing_4 = (props: Props) => {
  return (
    <section className="text-gray-700 body-font overflow-hidden hidden lg:block relative">
      <div className="mx-auto max-w-[58rem] text-center pt-12 md:pt-40">
        <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl text-[#272735]">
          Optimisez votre gestion de projet avec Flash Report
        </h2>
      </div>
      <div className="container px-5 pt-12 pb-24 mx-auto flex flex-wrap">
        <div className="lg:w-1/4 mt-32 hidden lg:block">
          <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
            {comparatifs.map((item, index) => (
              <p
                className={
                  index % 2 === 1
                    ? `bg-white text-gray-900 h-12 text-left px-4 flex items-center justify-start`
                    : `bg-[#EDECFB] text-gray-900 h-12 text-left px-4 flex items-center justify-start`
                }
                key={index}
              >
                {item.feature}
              </p>
            ))}
          </div>
        </div>
        <div className="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-lg">
          {/* <div className="lg:w-1/3 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
            <div className="px-2 text-center h-32 flex flex-col items-center justify-center border-b border-gray-300">
              <h2 className="text-2xl text-gray-900 font-medium leading-none mb-4 mt-2">
                GRATUIT
              </h2>
            </div>
            {comparatifs.map((item, index) =>
              item.free === true ? (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 text-center h-12 flex items-center justify-center`
                      : `bg-white text-gray-600 text-center h-12 flex items-center justify-center`
                  }
                  key={index}
                >
                  <span className="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                </p>
              ) : item.free !== false ? (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 h-12 text-center px-2 flex items-center justify-center`
                      : `bg-white h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center`
                  }
                  key={index}
                >
                  {item.free}
                </p>
              ) : (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 text-center h-12 flex items-center justify-center`
                      : `bg-white text-gray-600 text-center h-12 flex items-center justify-center`
                  }
                  key={index}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.2"
                    className="w-5 h-5 text-gray-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 6L6 18M6 6l12 12"></path>
                  </svg>
                </p>
              )
            )}
          </div> */}
          <div className="lg:w-2/4 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
            <div className="px-2 text-center h-32 flex flex-col items-center justify-center border-b border-gray-300">
              <h2 className="text-2xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                PRO
              </h2>
            </div>
            {comparatifs.map((item, index) =>
              item.premium === true ? (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 text-center h-12 flex items-center justify-center`
                      : `bg-white text-gray-600 text-center h-12 flex items-center justify-center`
                  }
                  key={index}
                >
                  <span className="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                </p>
              ) : item.premium !== false ? (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 h-12 text-center px-2 flex items-center justify-center`
                      : `bg-white h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center`
                  }
                  key={index}
                >
                  {item.premium}
                </p>
              ) : (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 text-center h-12 flex items-center justify-center`
                      : `bg-white text-gray-600 text-center h-12 flex items-center justify-center`
                  }
                  key={index}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.2"
                    className="w-5 h-5 text-gray-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 6L6 18M6 6l12 12"></path>
                  </svg>
                </p>
              )
            )}
          </div>
          <div className="lg:w-2/4 w-full lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
            <div className="px-2 text-center h-32 flex flex-col items-center justify-center border-b border-gray-300">
              <h2 className="text-2xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                ENTREPRISE
              </h2>
            </div>
            {comparatifs.map((item, index) =>
              item.enterprise === true ? (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 text-center h-12 flex items-center justify-center`
                      : `bg-white text-gray-600 text-center h-12 flex items-center justify-center`
                  }
                  key={index}
                >
                  <span className="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="w-3 h-3"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 6L9 17l-5-5"></path>
                    </svg>
                  </span>
                </p>
              ) : item.enterprise !== false ? (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 h-12 text-center px-2 flex items-center justify-center`
                      : `bg-white h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center`
                  }
                  key={index}
                >
                  {item.enterprise}
                </p>
              ) : (
                <p
                  className={
                    index % 2 === 0
                      ? `bg-[#EDECFB] text-gray-600 text-center h-12 flex items-center justify-center`
                      : `bg-white text-gray-600 text-center h-12 flex items-center justify-center`
                  }
                  key={index}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.2"
                    className="w-5 h-5 text-gray-500"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 6L6 18M6 6l12 12"></path>
                  </svg>
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default pricing_4;
