"use client";
import React, { useState } from "react";
import Image from "next/image";
import { Bg_img, Bg_img_IA } from "@/public";
import Link from "next/link";

type Props = {};

const pricing_10 = (props: Props) => {
  const [isMonthYear, setIsMonthYear] = useState(false);

  const handleClick = (item: boolean) => {
    setIsMonthYear(item);
  };

  const plans = [
    // {
    //   title: "Gratuit",
    //   subtitle: "Offre gratuite",
    //   description:
    //     "Profitez d'une interface intuitive et de fonctionnalités automatisées pour une efficacité quotidienne.",
    //   price: isMonthYear ? "0€" : "0€",
    //   features: [
    //     { text: "Accès de base à la gestion de projet" },
    //     { text: "Fonctionnalités essentielles incluses" },
    //     {
    //       text: "Idéal pour les pétites équipes ou les projets individuels",
    //     },
    //   ],
    // },
    {
      title: "Premium",
      subtitle: "Le meilleur de Flash Report",
      description:
        "Intégrez notre IA exceptionnelle pour vos décisions stratégiques et une analyse de données avancée.",
      price: isMonthYear ? "44,1€/mois" : "49€/mois",
      features: [
        { text: "Fonctions avancées de gestion de projet" },
        { text: "Optimisation de la gestion de projet grâce à  l'IA" },
        { text: "Intégration supplémentaires et export PowerPoint" },
      ],
    },
    {
      title: "Enterprise",
      subtitle: "Service exceptionnel",
      description:
        "Avec un accompagnement personnalisé et toutes nos fonctionnalités, c'est la solution ultime en gestion de projet.",
      price: isMonthYear ? "Sur devis" : "Sur devis",
      features: [
        { text: "Toutes les fonctionnalités de l'offre Premium" },
        { text: "Solution personnalisées pour grandes entreprises" },
        { text: "Support dédié et SLA garanti" },
      ],
    },
  ];

  return (
    <section
      className="bg-transparent pt-20 md:pt-32 lg:pt-40 relative min-h-screen pb-20"
      id="pricing"
      aria-label="Pricing Section"
    >
      <div className="absolute -z-10 inset-0 top-0 left-0 h-full w-full">
        <Image
          alt="background image"
          className="object-cover object-center w-full h-full"
          src={Bg_img}
        />
      </div>
      <div className="mx-auto max-w-[58rem] text-center">
        <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl text-[#272735]">
          Optimisez votre gestion de projet avec Flash Report
        </h2>
      </div>
      <div className="mx-auto max-w-xl px-6 lg:px-8 mb-6 pt-8 md:pt-16 lg:pt-24">
        <div className="flex flex-auto justify-evenly rounded-md">
          <button
            className={`${
              !isMonthYear ? "bg-[#4F46E5] text-white" : "border-none"
            } px-2 py-1 rounded-md w-full transition-all duration-300`}
            onClick={() => handleClick(false)}
          >
            Mensuel
          </button>

          <button
            className={`${
              isMonthYear ? "bg-[#4F46E5] text-white" : "border-none"
            } px-2 py-1 rounded-md w-full transition-all duration-300`}
            onClick={() => handleClick(true)}
          >
            Annuel
          </button>
        </div>
      </div>
      <main className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-7xl lg:grid-cols-2 lg:gap-8">
          {plans.map((plan, index) => (
            <article
              className="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10"
              key={index}
            >
              <header className="p-8 sm:p-10">
                <div className="flex">
                  <h3
                    className="text-lg font-semibold leading-8 tracking-tight text-indigo-600 dark:text-teal-400"
                    id="tier-basic"
                  >
                    {plan.title}
                  </h3>
                </div>
                <div className="mt-4 flex items-baseline text-5xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
                  {plan.price}
                  <span className="text-lg font-semibold leading-8 tracking-normal text-gray-500 dark:text-gray-400"></span>
                </div>
                <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-300">
                  {plan.description}
                </p>
              </header>
              <div className="flex flex-1 flex-col p-2">
                <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 dark:bg-gray-700 p-6 sm:p-8">
                  <ul role="list" className="space-y-6">
                    {plan.features.map((item, index_feat) => (
                      <li className="flex items-start" key={index_feat}>
                        <div className="flex-shrink-0">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 6L9 17l-5-5"></path>
                          </svg>
                        </div>
                        <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                          {item.text}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-8">
                    <Link
                      href="/contact"
                      className="inline-block w-full rounded-lg bg-indigo-600 dark:bg-teal-400 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-teal-700 dark:hover:bg-teal-500 cursor-pointer"
                      aria-describedby="tier-basic"
                    >
                      Choisir l'offre
                    </Link>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </main>
    </section>
  );
};

export default pricing_10;
